import {createSlice} from '@reduxjs/toolkit';
import {StrategiesModal} from './types';

export interface State {
  data: any;
  error: any;
}

const initialState: State = {
  data: undefined,
  error: undefined,
};

export const strategiesModalSlice = createSlice({
  name: StrategiesModal,
  initialState,
  reducers: {
    getAEMData: () => {},
    aemApiCallSuccess: (state, action) => {
      state.data = action.payload;
      if (state.error !== undefined) {
        state.error = undefined;
      }
    },
    aemApiCallFailure: (state, action) => {
      if (state.data !== undefined) {
        state.data = undefined;
      }
      state.error = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {getAEMData, aemApiCallSuccess, aemApiCallFailure} =
  strategiesModalSlice.actions;

export default strategiesModalSlice.reducer;
