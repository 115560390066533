const initialState = {
  getTransferBalanceData: {},
  getDollarTransferData: {},
  token: {},
  isError: false,
  error: '',
  loading: false,
};

const transferBalanceReducer = (updatedState, {type, payload}) => {
  const state = updatedState ?? initialState;
  const actionMap = {
    GET_TRANSFER_TRANSACTION_REQUEST: {
      ...state,
      token: payload?.token,
      isError: false,
      loading: true,
    },
    GET_TRANSFER_TRANSACTION_SUCCESS: {
      ...state,
      getTransferBalanceData: payload,
      isError: false,
      error: '',
      loading: false,
    },
    GET_TRANSFER_TRANSACTION_FAILURE: {
      ...state,
      error: payload?.error,
      isError: true,
      loading: false,
    },
    GET_DOLLAR_TRANSACTION_REQUEST: {
      ...state,
      token: payload?.token,
      isError: false,
      loading: true,
    },
    GET_DOLLAR_TRANSACTION_SUCCESS: {
      ...state,
      getDollarTransferData: payload,
      isError: false,
      error: '',
      loading: false,
    },
    GET_DOLLAR_TRANSACTION_FAILURE: {
      ...state,
      error: payload?.error,
      isError: true,
      loading: false,
    },
  };
  if (actionMap[type]) {
    return actionMap[type];
  }
  return state;
};
export default transferBalanceReducer;
