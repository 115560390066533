//RootReducer combined with all Modules's Reducer

import {combineReducers} from 'redux';
import policiesReducer from './policies/reducer';
import policyServiceRequestSlice from './policyservicerequest/policyServiceRequestSlice';
import indedxeddividendReducer from './indexeddividend/reducer';
import policyListsSlice from './policylists/policyListsSlice';
import profileDataReducer from './profileDetails/reducer';
import investmentOptionsReducer from './investmentoptions/reducer';
import transferBalanceReducer from './TransferBalance/reducer';
import strategiesModalSlice from './strategiesInfo/strategiesModalSlice';
import notificationReducer from './notification/reducer';
import genericNotificationReducer from './genericnotification/reducer';
import myReportsSlice from './myReports/myReportsSlice';

const rootReducer = combineReducers({
  policies: policiesReducer,
  policyServiceRequest: policyServiceRequestSlice,
  indedxeddividendReducer,
  policyLists: policyListsSlice,
  profileData: profileDataReducer,
  investments: investmentOptionsReducer,
  transferBalance: transferBalanceReducer,
  strategiesModal: strategiesModalSlice,
  notification: notificationReducer,
  genericNotification: genericNotificationReducer,
  myReports: myReportsSlice,
});
export default (state, action) =>
  rootReducer(action.type === 'RESET_LOGOUT_FLAG' ? undefined : state, action);
