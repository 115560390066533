import {
  GET_NOTIFICATION_REQUEST,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAILURE,
} from './constant';

const initialState = {notificationData: {}, loading: false, error: null};

const notificationReducer = (updatedState, actions) => {
  const state = updatedState ?? initialState;
  switch (actions.type) {
    case GET_NOTIFICATION_REQUEST:
      return {...state, loading: true, error: null};
    case GET_NOTIFICATION_SUCCESS:
      return {...state, loading: false, notificationData: actions.payload};
    case GET_NOTIFICATION_FAILURE:
      return {...state, loading: true, error: actions.payload};
    default:
      return state;
  }
};

export default notificationReducer;
