import {analyticsTags} from './../../utils/Analytics/AnalyticsTags';
import {trackCustomEvent} from './../../utils/Analytics/AdobeAnalyticsUtils';
import {put, takeLatest} from 'redux-saga/effects';
import {NetworkManager} from '../../api/NetworkManager';
import {
  getProfileDataSuccess,
  getProfileDataFailure,
  updateProfileDataSuccess,
  updateProfileDataFailure,
} from './action';
import {GET_PROFILE, UPDATE_PROFILE_INFO} from './constant';

export function* getViewProfileData(action) {
  try {
    const response = yield NetworkManager.getProfileData(action.payload.token);
    if (response) {
      yield put(getProfileDataSuccess(response.data));
    }
  } catch (error) {
    yield put(getProfileDataFailure(error));
  }
}

const trackProfileUpdateEvent = (data) => {
  if (data?.contactInfo) {
    return 'CaptureContactInformationSubmitSuccessEventTags';
  } else if (data?.userQuestion) {
    return 'CaptureSecurityQuestionSubmitSuccessEventTags';
  } else if (data?.logonInfo?.newPassword) {
    return 'CaptureChangePasswordSubmitSuccessEventTags';
  } else if (data?.logonInfo?.newLogonId) {
    return 'CaptureChangeUserIDSubmitSuccessEventTags';
  }
  return null;
};

export function* updateViewProfileData(action) {
  const {data, token} = action.payload;
  try {
    const response = yield NetworkManager.updateProfileData(data, token);
    if (response.status === 204) {
      const getResponse = yield NetworkManager.getProfileData(token);
      if (getResponse) {
        const eventTag = trackProfileUpdateEvent(data);
        if (eventTag && typeof eventTag === 'string') {
          trackCustomEvent(analyticsTags[eventTag]);
        }
        yield put(getProfileDataSuccess(getResponse.data));
        yield put(updateProfileDataSuccess(true));
      }
    } else {
      const eventTag = trackProfileUpdateEvent(data);
      if (eventTag && typeof eventTag === 'string') {
        const eventName = eventTag.replace('Success', 'Failure');
        trackCustomEvent(analyticsTags[eventName]);
      }
      yield put(updateProfileDataFailure(response));
    }
  } catch (error) {
    const eventTag = trackProfileUpdateEvent(data);
    if (eventTag && typeof eventTag === 'string') {
      const eventName = eventTag.replace('Success', 'Failure');
      trackCustomEvent(analyticsTags[eventName]);
    }
    yield put(updateProfileDataFailure(error));
  }
}

export function* watchProfileSaga() {
  yield takeLatest(GET_PROFILE, getViewProfileData);
  yield takeLatest(UPDATE_PROFILE_INFO, updateViewProfileData);
}
