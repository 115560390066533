import {put, takeLatest} from 'redux-saga/effects';
import {NetworkManager} from '../../api/NetworkManager';
import {
  myReportsApiCallFailure,
  myReportsApiCallSuccess,
  myReportsApiCallLoadingRequest,
  myReportsDayStartLoading,
  myReportsDayApiCallSuccess,
  myReportsDayApiCallFailure,
} from './myReportsSlice';
import {GET_MY_REPORTS_DATA, GET_MY_REPORTS_DAY_DATA} from './types';
export function* getMyReportsRequestData(action) {
  try {
    yield put(myReportsApiCallLoadingRequest(action.payload.isLoading));
    const response = yield NetworkManager.getReportMonthAndYear({
      token: action.payload.token,
      policyNumber: action.payload.policyNumber,
      month: action.payload.month,
      year: action.payload.year,
      investmentIds: action.payload.investmentIds,
    });
    if (response) {
      yield put(myReportsApiCallSuccess(response.data));
    }
  } catch (error) {
    yield put(myReportsApiCallFailure(error));
  }
}

export function* getMyReportsRequestDayData(action) {
  try {
    yield put(myReportsDayStartLoading(action.payload.isLoading));
    const response = yield NetworkManager.getReportByDay({
      token: action.payload.token,
      policyNumber: action.payload.policyNumber,
      day: action.payload.day,
    });
    if (response) {
      yield put(myReportsDayApiCallSuccess(response.data));
    }
  } catch (error) {
    yield put(myReportsDayApiCallFailure(error));
  }
}

export function* watchMyReports() {
  yield takeLatest(GET_MY_REPORTS_DATA, getMyReportsRequestData);
  yield takeLatest(GET_MY_REPORTS_DAY_DATA, getMyReportsRequestDayData);
}
