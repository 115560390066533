import {put, takeLatest} from 'redux-saga/effects';
import {NetworkManager} from '../../api/NetworkManager';
import {aemApiCallFailure, aemApiCallSuccess} from './strategiesModalSlice';
import {GET_STRATEGY_MODAL_DATA} from './types';

export function* getStrategyModalData() {
  try {
    const response = yield NetworkManager.getStrategiesModal();
    if (response) {
      yield put(aemApiCallSuccess(response.data));
    }
  } catch (error) {
    yield put(aemApiCallFailure(error));
  }
}

export function* watchStrategyModalDataRequest() {
  yield takeLatest(GET_STRATEGY_MODAL_DATA, getStrategyModalData);
}
