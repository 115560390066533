import {put, takeEvery} from 'redux-saga/effects';
import {
  getTransferBalanceSuccess,
  getTransferBalanceFailure,
  getTransferDollarSuccess,
  getTransferDollarRequestFailure,
} from './action';
import {NetworkManager} from '../../api/NetworkManager';
import {
  GET_DOLLAR_TRANSACTION_REQUEST,
  GET_TRANSFER_TRANSACTION_REQUEST,
} from './constant';

function* fetchTransferTransaction(action) {
  try {
    const response = yield NetworkManager.getTransferTransaction({
      token: action.payload.token,
      policyNumber: action.payload.policyNumber,
      transactiontype: action.payload.transactiontype,
    });
    if (response && response.status === 200) {
      yield put(getTransferBalanceSuccess(response.data));
    } else {
      yield put(getTransferBalanceFailure(response));
    }
  } catch (e) {
    yield put(getTransferBalanceFailure(e));
  }
}
function* fetchDollarTransaction(action) {
  try {
    const response = yield NetworkManager.getTransferTransaction({
      token: action.payload.token,
      policyNumber: action.payload.policyNumber,
      transactiontype: action.payload.transactiontype,
    });
    if (response && response.status === 200) {
      yield put(getTransferDollarSuccess(response.data));
    } else {
      yield put(getTransferDollarRequestFailure(response));
    }
  } catch (e) {
    yield put(getTransferDollarRequestFailure(e));
  }
}

export function* watchTransferTransaction() {
  yield takeEvery(GET_TRANSFER_TRANSACTION_REQUEST, fetchTransferTransaction);
}
export function* watchDollarTransferTransaction() {
  yield takeEvery(GET_DOLLAR_TRANSACTION_REQUEST, fetchDollarTransaction);
}
